<template>
  <v-select
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    color="tertiary"
    :label="$t('hr.travel.detail_subtype')"
    item-value="value"
    :item-text="item => $t(`hr.travel.travel_detail_subtype_enum.${item.text}`)"
    :items="items"
    :menu-props="{ offsetY: true, overflowY: true }"
    @input="$emit('input', $event)"
  />
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-29 08:21:25
   * @modify date 2020-12-29 08:21:25
   * @desc TravelDetailSubTypePicker
   */
  import { TravelDetailSubTypeEnum } from "../enums";
  export default {
    name: "TravelDetailSubtypePicker",
    inheritAttrs: false,
    props: {
      value: String,
      detailType: String
    },
    data: vm => ({
      items: []
    }),
    watch: {
      detailType: {
        immediate: true,
        handler(detailType, prevDetailType) {
          if (detailType) {
            this.items = this.$helpers.fromEnumToArray(TravelDetailSubTypeEnum[detailType]);
            if (!this.items.some(item => item.value === this.value)) {
              this.$emit("input", null);
            }
          } else {
            this.items = [];
          }
        }
      }
    }
  };
</script>

<style></style>
